<template>
  <div class="w-full">
    <div class="relative">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
      >
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <vue-select
        v-model="search"
        :options="data ? data : quickData"
        label-by="label"
        track-by="label"
        :close-on-select="closeOnSelect ? true : false"
        searchable
        :loading="isLoading"
        :multiple="isMultiple ? true : false"
        :placeholder="selectedCustomer ? selectedCustomer : placeholder"
        @search:input="handleSearchInput"
        :maxHeight="maxHeight"
        class="text-left block w-1/2 bg-white border border-gray-300 rounded-md py-1 pl-10 pr-3 text-sm placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 encom_primary_border_focus sm:text-sm z-20"
      ></vue-select>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";
import { SearchIcon } from "@heroicons/vue/outline";
export default {
  props: [
    "placeholder",
    "selectedCustomer",
    "searchMethod",
    "data",
    "closeOnSelect",
    "isMultiple",
    "selectAllElements",
    "clearAllElements",
    "elementToRemove",
    "maxHeight",
    "selectedElements",
    "selectedElementsLength",
  ],
  components: {
    VueSelect,
    SearchIcon,
  },
  data() {
    return {
      quickData: [],
      search: "",
      searchableTerm: "",
      isLoading: false,
      isAllSelected: true,
    };
  },
  methods: {
    handleSearchInput(event) {
      this.searchableTerm = event.target.value;
    },
    async getQuick(term) {
      try {
        if (term) {
          this.isLoading = true;
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`
          );
          console.log(res.data);
          this.quickData = res.data;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
    getSelectedElementsFromGroups() {
      let arr = [];
      if (this.data.length > 0 && this.selectedElements) {
        for (let index = 0; index < this.selectedElements.length; index++) {
          const selectedElement = this.selectedElements[index];
          this.data.forEach((element) => {
            if (element.value == selectedElement) {
              arr.push(element);
            }
          });
        }
      }
      this.search = arr;
    },
  },
  mounted() {
    this.getSelectedElementsFromGroups();
  },
  watch: {
    searchableTerm: function(val) {
      if (this.searchMethod) {
        if (val.length > 2) {
          this.searchMethod(val);
        }
      }
    },
    search: function(val) {
      this.isLoading = false;
      this.$store.dispatch("pbxElements", val);
      this.$emit("action", val);
    },
    selectAllElements: function(val) {
      this.search = this.data;
      this.$emit("all-selected", true);
    },
    clearAllElements: function(val) {
      this.search = "";
      this.$emit("all-selected", false);
    },
    elementToRemove: {
      handler(val) {
        const filteredSearch = this.search.filter((element) => element != val);
        this.search = filteredSearch;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
